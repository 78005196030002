<template>
  <base-section id="home-first">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
          offset-md="3"
        >
          <base-section-heading
            color="primary"
            title="Présentation"
            align="left"
          />
          <p>Nous sommes une jeune société indépendante spécialisée dans la serrurerie, la construction métallique et l’aménagement de véhicules.</p>
          <p>Nous ne cessons de nous développer au fil de projets personnalisés.</p>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionHomeFirst',

    data: () => ({
    }),
  }
</script>
